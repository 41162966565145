import React from "react";

// Define types for props
interface ContentItem {
  order: number;
  title: string;
  description: string;
}

interface RenderContentProps {
  content: ContentItem[];
}

// Email regex pattern
const emailPattern = /(\S+@\S+\.\S+)/g;

const RenderContent: React.FC<RenderContentProps> = ({ content }) => {
  // Function to make email addresses clickable
  const makeEmailClickable = (text: string, order: number) => {
    return text.split(emailPattern).map((part, index) =>
      emailPattern.test(part) ? (
        <a
          key={`${order}.${index}`}
          href={`mailto:${part}`}
          className="link-text"
        >
          {part}
        </a>
      ) : (
        highlightBackticks(part, order)
      )
    );
  };

  // Function to highlight text between backticks
  const highlightBackticks = (text: string, order: number) => {
    if (!text) return text;

    const parts = text.split(/(`[^`]+`)/g);
    return parts.map((part, index) =>
      part.startsWith("`") && part.endsWith("`") ? (
        <span key={`${order}-${index}`} className="highlight">
          {part.slice(1, -1)}
        </span>
      ) : (
        <span key={`${order}-${index}`}>{part}</span>
      )
    );
  };

  if (content?.length > 0) {
    return (
      <div>
        <p className="paragraph">{content[0].description}</p>
        {content.slice(1).map((item) => (
          <div key={item.order}>
            <h3 className="title">{`${item.order}. ${item.title}`}</h3>
            <p className="paragraph">
              {makeEmailClickable(item.description, item.order)}
            </p>
          </div>
        ))}
      </div>
    );
  } else return null;
};

export default RenderContent;
