// src/components/Layout.jsx
import { Link, Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="">
      {/* Header */}
      <header className="bg-[rgba(33,96,100,0.83)] shadow-md">
        <div className="container mx-auto flex items-center justify-between px-4 py-2">
          <Link to="/" className="text-white text-3xl font-bold">
            Truuze
          </Link>

          <img src="/icon.png" alt="App Icon" height={31.5} width={36} />
        </div>
      </header>

      {/* Main content where child routes will be rendered */}
      <main className="flex-grow  mx-auto ">
        <Outlet /> {/* Renders child routes */}
      </main>
    </div>
  );
};

export default Layout;
