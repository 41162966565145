import LoadingSpinner from "../components/loading";
import RenderContent from "../components/render-content";
import { useFetchPrivacyPolicy, useFetchTermsOfService } from "../hooks/use-fetch";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

function UserAgreement() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<"terms" | "privacy">("privacy");

  // Fetch data using custom hooks
  const { terms, isLoading: loadingTerms } = useFetchTermsOfService();
  const { privacy, isLoading: loadingPrivacy } = useFetchPrivacyPolicy();

  // Set initial tab based on URL parameter
  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "terms" || tab === "privacy") {
      setActiveTab(tab);
    }
  }, [searchParams]);

  // Update tab state and URL parameter on button click
  const handleTabClick = (tab: "terms" | "privacy") => {
    setActiveTab(tab);
    navigate(`/user-agreement?tab=${tab}`);
  };

  return (
    <>
      {/* Header */}

      {/* Tabs: Terms of Service and Privacy Policy */}
      <div className="mx-auto flex justify-between ">
        <button
          onClick={() => handleTabClick("privacy")}
          className={`w-1/2 text-lg font-semibold px-4 py-2 transition heading-text ${activeTab === "privacy"
            ? "bg-[#EDE7E7] text-[#698599]"
            : "bg-[#B7C2DE] text-[#012943]"
            }`}
        >
          Privacy Policy
        </button>
        <button
          onClick={() => handleTabClick("terms")}
          className={`w-1/2 text-lg font-semibold px-4 py-2 transition heading-text ${activeTab === "terms"
            ? "bg-[#EDE7E7] text-[#698599]"
            : "bg-[#B7C2DE] text-[#012943]"
            }`}
        >
          Terms of Service
        </button>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-6">
        {activeTab === "terms" ? (
          loadingTerms ? (
            <LoadingSpinner />
          ) : (
            terms && <RenderContent content={terms} />
          )
        ) : loadingPrivacy ? (
          <LoadingSpinner />
        ) : (
          privacy && <RenderContent content={privacy} />
        )}
      </div>
    </>
  );
}

export default UserAgreement;
