const LoadingSpinner = () => (
  <div className="flex flex-col justify-center items-center">
    <div
      className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
      role="status"
    ></div>
    <div className="visually-hidden">Loading...</div>
  </div>
);

export default LoadingSpinner;
