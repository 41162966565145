import { useEffect, useState } from "react";
import axios from "axios";

// Read API base URL and API key from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

// Create an Axios instance with the API key
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "x-api-key": API_KEY,
    "Content-Type": "application/json",
  },
});

// Hook to fetch Terms of Service
export const useFetchTermsOfService = () => {
  const [terms, setTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await apiClient.get("/config/terms-of-service/");
        setTerms(response.data);
      } catch (error) {
        console.error("Error fetching Terms of Service:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTerms();
  }, []);

  return { terms, isLoading };
};

// Hook to fetch Privacy Policy
export const useFetchPrivacyPolicy = () => {
  const [privacy, setPrivacy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPrivacy = async () => {
      try {
        const response = await apiClient.get("/config/privacy-policy/");
        setPrivacy(response.data);
      } catch (error) {
        console.error("Error fetching Privacy Policy:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrivacy();
  }, []);

  return { privacy, isLoading };
};
